<template>
  <div class="page-container gray-100">
    <div class="content-band"><h2>Primary Header Demo:</h2></div>
    <header>
      <nav aria-labelledby="main menu" role="navigation">
        <div class="nav-OH-strip"></div>

        <div id="navContainerDemo" class="nav_container iop-drk-blue">
          <div class="nav-bar max-w1440 min-h90">
            <!-- Logo -->
            <div id="logo" class="container logo max-w170">
              <img
                class=""
                src="../../../assets/ohid-logo-white-id.png"
                style="width: 165px; height: auto;"
                alt="OHID logo"
                tabindex="0"
              />
            </div>

            <!-- Main Nav Links -->
            <div id="menu" class="container basis-60">
              <div
                id="navLinks"
                class="nav-links justify-center font-white font-18"
                role="menubar"
              >
                <div class="nav-link-item" role="menuitem">
                  <a href="#" class="navBtn active" tabindex="0">
                    My Apps
                  </a>
                </div>

                <div class="nav-link-item mega" role="menuitem">
                  <a href="#" onclick="" class="navBtn" tabindex="0">
                    App Store
                  </a>
                </div>

                <div class="nav-link-item" role="menuitem">
                  <a href="#" class="navBtn" tabindex="0">
                    Account Settings
                  </a>
                </div>

                <div class="nav-link-item" role="menuitem">
                  <a href="#" class="navBtn" tabindex="0">
                    ID Assurance
                  </a>
                </div>

                <div class="nav-link-item" role="menuitem">
                  <a href="#" class="navBtn" tabindex="0">
                    Recent Activity
                  </a>
                </div>
              </div>

              <!-- Injected Search Markup -->
              <div id="searchContainer">
                <form class="search skip" action="">
                  <div class="search-field-container skip">
                    <div class="search-icon icon-black gray-200 skip">
                      <i id="searchIcon" class="far fa-search"></i>
                    </div>
                    <input
                      id="desktopSearch"
                      class="skip"
                      type="text"
                      placeholder="Search"
                      name="search"
                      aria-label="Type your search and press enter."
                    />
                  </div>

                  <button id="desktopSearch-btn" class="skip" type="submit">
                    Search
                  </button>
                </form>
              </div>
            </div>

            <!-- nav-utility-links -->
            <div id="utilities" class="container nav-utility-links skip">
              <button
                id="utility-login"
                class="rounded-full flex-row-reverse mr-10"
              >
                <div class="btn-txt font-white">Log in</div>
                <div class="icon font-white font-20">
                  <i class="fal fa-user-circle"></i>
                </div>
              </button>

              <button
                id="utility-contact"
                class="rounded-full flex-row-reverse mr-10"
              >
                <div class="btn-txt font-white">Contact</div>
                <div class="icon font-white font-20">
                  <i class="fal fa-question-circle"></i>
                </div>
              </button>

              <!-- Search Button -->
              <button
                id="searchBtn"
                class="rounded-full flex-row-reverse skip"
                @click="toggleSearch()"
              >
                <div id="searchBtnTxt" class="btn-txt font-white skip">
                  Search
                </div>
                <div id="searchBtnCloseTxt" class="btn-txt skip">Close</div>

                <div class="icon pr-10">
                  <!-- Close Search Icon -->
                  <i id="closeIcon" class="fal fa-times font-20 skip"></i>

                  <!-- Open Search Icon -->
                  <i id="searchIcon" class="far fa-search font-white"></i>
                </div>
              </button>
            </div>
            <!-- END / nav-utility-links -->
          </div>
        </div>
      </nav>
    </header>

    <!-- MOBILE NAVIGATION -->

    <div class="display-none" id="mobile-nav-container">
      <div class="mobile-nav">
        <div class="mobile-overlay white" id="m-overlay"></div>

        <div class="mobile-header-container">
          <div class="mobile-header">
            <img
              src="/html-components/SVG/logoipsum.svg"
              style="width: 150px;"
            />
          </div>
        </div>

        <div class="mobile-subhead red-700 flex" id="mobileSubHead">
          <div
            class="container flex basis-50 font-white align-center justify-center mr-10"
          >
            <div class="mr-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 28.13 21.87"
                class="icon-white"
                height="20"
              >
                <path
                  class="cls-1"
                  d="M28.12,11.12a.75.75,0,0,1-.18.5l-.5.6a.78.78,0,0,1-.6.28.8.8,0,0,1-.5-.18L25,11.21v9.11a1.57,1.57,0,0,1-1.56,1.55H17.19a1.58,1.58,0,0,1-1.57-1.56V15.23H12.5v5.08a1.56,1.56,0,0,1-1.56,1.56H4.69a1.58,1.58,0,0,1-1.57-1.56v-9.1l-1.34,1.1a.74.74,0,0,1-.5.19.8.8,0,0,1-.6-.28l-.5-.6a.8.8,0,0,1-.18-.5.78.78,0,0,1,.28-.6l12-9.89a2.75,2.75,0,0,1,3.5,0l6.85,5.62V3.51a.39.39,0,0,1,.39-.39h1.56a.4.4,0,0,1,.39.39V8.18l2.84,2.34A.75.75,0,0,1,28.12,11.12ZM22.66,9.29l-8.6-7.06L5.47,9.29V19.53h4.69V14.45a1.56,1.56,0,0,1,1.56-1.56h4.69A1.56,1.56,0,0,1,18,14.45v5.08h4.68Z"
                />
              </svg>
            </div>
            <div class="font-18">Home</div>
          </div>

          <div
            class="align-self-center red-100"
            style="width: 2px; height: 70%;"
          ></div>

          <div
            class="container flex basis-50 font-white align-center justify-center ml-10"
          >
            <div class="mr-10 height-full flex align-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24.22 24.22"
                class="icon-white"
                height="20"
              >
                <path
                  class="cls-1"
                  d="M24.22,12.11A12.11,12.11,0,1,1,12.11,0,12.11,12.11,0,0,1,24.22,12.11Zm-2.34,0a9.77,9.77,0,1,0-9.77,9.77A9.77,9.77,0,0,0,21.88,12.11ZM13.81,14v.31a.58.58,0,0,1-.59.58H11a.58.58,0,0,1-.59-.58v-.43c0-1.74,1.32-2.44,2.32-3s1.39-.81,1.39-1.44C14.12,8.54,13,8,12.17,8,11,8,10.52,8.52,9.78,9.45a.58.58,0,0,1-.81.1l-1.36-1a.59.59,0,0,1-.13-.8,5.5,5.5,0,0,1,4.91-2.64c2.4,0,5,1.87,5,4.33C17.35,12.69,13.81,12.74,13.81,14Zm-1.7,5.68a2.05,2.05,0,1,1,2.05-2.05A2.05,2.05,0,0,1,12.11,19.63Z"
                />
              </svg>
            </div>
            <div class="font-18">Help</div>
          </div>
        </div>

        <div class="" id="mobile-navlinks">
          <div class="navlinks-container semibold">
            <div class="active">Section Ipsum</div>
            <div>About this company</div>
            <div>Link Three</div>
            <div>Link Four</div>
            <div>Link Five</div>
          </div>
        </div>

        <div class="display-none" id="mobile-search">
          <div class="search-container flex">
            <div
              class="width-full mlr-20 flex align-center"
              style="position: relative; height: 60px;"
            >
              <div
                class=""
                style="position: absolute; z-index: 20; font-weight: 600; font-size: 30px;"
              >
                Search
              </div>

              <input
                class="width-full min-h60"
                id="Search"
                type="text"
                name="firstName"
                autocomplete="off"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="display-mobile">
      <div class="mobile-fixed-nav">
        <div
          class="fixed-nav-item flex align-center justify-center"
          id="mobileMenuBtn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            viewBox="0 0 27 24"
            class="icon"
          >
            <path
              d="M.68,3.17A.67.67,0,0,1,0,2.49V.68A.67.67,0,0,1,.68,0h27a.68.68,0,0,1,.68.68V2.49a.68.68,0,0,1-.68.68Zm0,9.06A.67.67,0,0,1,0,11.55V9.74a.68.68,0,0,1,.68-.68h27a.69.69,0,0,1,.68.68v1.81a.68.68,0,0,1-.68.68Zm0,9.07A.68.68,0,0,1,0,20.62V18.8a.68.68,0,0,1,.68-.68h27a.69.69,0,0,1,.68.68v1.82a.69.69,0,0,1-.68.68Z"
            />
          </svg>
          <div class="btn-label mlr-10 font-18">Menu</div>
          <div id="mobile-open" class=""></div>
          <div id="mobile-close" class="display-none"></div>
        </div>

        <div
          class="fixed-nav-item align-center justify-center"
          id="mobileSearchBtn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            viewBox="0 0 29 29"
            class="icon"
          >
            <!-- <path d="M28.6,27,27,28.6a1.35,1.35,0,0,1-1.93,0L19.43,23a1.35,1.35,0,0,1-.4-1v-.92a11.77,11.77,0,1,1,2-2H22a1.35,1.35,0,0,1,1,.4l5.64,5.64A1.37,1.37,0,0,1,28.6,27ZM19,11.78A7.25,7.25,0,1,0,11.78,19,7.24,7.24,0,0,0,19,11.78Z"/> -->
            <path
              d="M28.8,27.52,27.52,28.8a.67.67,0,0,1-1,0l-6.88-6.87a.72.72,0,0,1-.2-.49V20.7a11.77,11.77,0,1,1,1.22-1.22h.74a.68.68,0,0,1,.49.2l6.87,6.88A.67.67,0,0,1,28.8,27.52Zm-8-15.74a9.06,9.06,0,1,0-9.06,9.06A9.05,9.05,0,0,0,20.84,11.78Z"
            />
          </svg>
          <div class="btn-label mlr-10 font-18">Search</div>
          <div id="search-open" class=""></div>
          <div id="search-close" class="display-none"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  components: {},
  data: function() {
    return {};
  },
  methods: {
    toggleSearch() {
      console.log(this.navContainerDemo);
      var navContainerDemo = document.getElementById("navContainerDemo");
      var searchBtn = document.getElementById("searchBtn");
      var searchBtnTxt = document.getElementById("searchBtnTxt");
      var desktopSearch = document.getElementById("desktopSearch");

      // MAIN NAVIGATION
      var myDropdown = document.getElementById("dropDownItem");
      var myMega = document.getElementById("megaMenu");

      searchBtn.classList.toggle("close");
      navContainerDemo.classList.toggle("search-active");
      searchBtnTxt.classList.toggle("display-none");
      document
        .getElementById("utility-contact")
        .classList.toggle("display-none");
      document.getElementById("utility-login").classList.toggle("display-none");
      desktopSearch.focus();
      myDropdown.classList.remove("dropdown-on");
      myMega.classList.remove("show");

      var navBtn = document.getElementsByClassName("navBtn");
      for (var i = 0; i < navBtn.length; i++) {
        navBtn[i].className = navBtn[i].className.replace(" active", "");
      }

      window.onclick = function(event) {
        if (
          !event.target.matches(
            ".skip, #navContainerDemo, .nav-bar, #searchContainer, .icon"
          )
        ) {
          if (navContainerDemo.classList.contains("search-active")) {
            navContainerDemo.classList.remove("search-active");
            searchBtn.classList.remove("close");
            navContainerDemo.classList.remove("search-active");
            searchBtnTxt.classList.remove("display-none");
          }
        }
      };
    },
  },
};
</script>
