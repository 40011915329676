<template>
  <div class="page-container gray-100">
    <div class="content-band"><h2>Footer Demo:</h2></div>

    <!-- Primary Footer -->
    <footers class="mt-auto" aria-label="Primary footer navigation">
      <div class="sub-footer-demo-container-demo gray-800">
        <div class="sub-footer-demo footer-container-demo container even-cols max-w1200">
          <div class="container justify-center">
            <div class="footer-category-demo" role="menu">
              <div class="footer-category-demo-title-demo font-white">Lorem Ipsum</div>
              <div class="footer-link-container-demo">
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Accusantium doloremque</a
                >
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >numquam eius modi</a
                >
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Lorem ipsum dolor</a
                >
              </div>
            </div>
          </div>

          <div class="container justify-center">
            <div
              class="footer-category-demo"
              role="menu"
              aria-label="Category one title"
            >
              <div class="footer-category-demo-title-demo font-white">
                Occaecati Cupid
              </div>
              <div class="footer-link-container-demo font-gray-500">
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Accusantium dolore mque</a
                >
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Placeat facere</a
                >
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >This is a link</a
                >
              </div>
            </div>
          </div>
          <div class="container justify-center">
            <div
              class="footer-category-demo"
              role="menu"
              aria-label="Category two title"
            >
              <div class="footer-category-demo-title-demo font-white">Category Title</div>
              <div class="footer-link-container-demo font-gray-500">
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Sed ut perspiciatis</a
                >
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Impedit quo minus</a
                >
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Ut aut reiciendis</a
                >
              </div>
            </div>
          </div>
          <div class="container justify-center">
            <div
              class="footer-category-demo"
              role="menu"
              aria-label="Category third title"
            >
              <div class="footer-category-demo-title-demo font-white">
                Temporibus perspiciatis
              </div>
              <div class="footer-link-container-demo font-gray-500">
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Et harum quidem</a
                >
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >Temporibus autem ut perspiciatis</a
                >
                <a href="" class="footer-link font-gray-500" role="menuitem"
                  >This is a link</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </footers>

    <!-- divider -->
    <div class="gray-800 plr-20">
      <div class="divider gray-700 max-w1440"></div>
    </div>

    <!-- Secondary Footer -->
    <footers
      class="secondary-footer-demo gray-800 mt-0"
      aria-label="Secondary footer navigation"
    >
      <div class="footer-container-demo">
        <div class="container logo">
          <img class="h30" src="../../../assets/iop-logo.svg" alt="IOP logo" />
        </div>

        <div class="container footer-links-demo" role="menu">
          <a href="" class="font-white" role="menuitem"
            >Privacy Notice and Policies</a
          >
          <a href="" class="font-white" role="menuitem">Accessibility</a>
          <a href="" class="font-white" role="menuitem">Ohio Checkbook</a>
          <div class="divider mt-30 show-mobile gray-700"></div>
        </div>

        <div class="container social-icons">
          <a href="" title="facebook"
            ><i class="fab fa-facebook icon-white" aria-hidden="true"></i
          ></a>
          <a href="" title="twitter"
            ><i class="fab fa-twitter icon-white" aria-hidden="true"></i
          ></a>
          <a href="" title="instagram"
            ><i class="fab fa-instagram icon-white" aria-hidden="true"></i
          ></a>
          <a href="" title="google-plus"
            ><i class="fab fa-google-plus-g icon-white" aria-hidden="true"></i
          ></a>
        </div>
      </div>
    </footers>
    <!-- End Footer -->
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  components: {},
  data: function() {
    return {};
  },
};
</script>
<style scoped>
/* ///////////////////////////
    FOOTER
////////////////////////////*/

footers.secondary-footer-demo{
  display: flex;
  width: 100%;  
  justify-content: center;
  margin-top: auto;
  padding: 0 20px;
}

.footer-container-demo{
  position: relative;
  display: flex;
  min-height: 60px; 
  width: 100%;
  max-width: 1440px;
  transition: all .2s ease-in-out;
}

.footer-container-demo > .container{
  align-items: center;
}
.footer-container-demo > .logo{
  flex-basis: 15%;
  min-width: 100px;
}
.footer-links-demo-demo {
  justify-content: center;
  flex-basis: 60%;
  
}
.footer-links-demo > a{
  padding: 0 20px;
  font-size: 15px;
}
.footer-links-demo > a:first-of-type{
  padding-left: 0;
}

.footer-container-demo > .container{
  display: flex;
  height: 100%;
}

.footer-category-demo-title-demo{
  padding: 20px 0;
  font-size: 18px;
}

.footer-container-demo > .container.social-icons{
  justify-content: flex-end;
  font-size: 20px;
  flex-basis: 25%;
}
.footer-container-demo > .container.social-icons > a{
  margin: 0 15px;
}
.footer-container-demo > .container.social-icons > a:last-of-type{
  margin-right: 0;
}


.footer-link-container-demo{
  display: flex;
  flex-direction: column;
}
.sub-footer-demo-container-demo{
  padding: 0 20px; 
  margin-top: auto;
}
.sub-footer-demo{
  padding: 20px 0 30px;
  margin: 0 auto;
}

.sub-footer-demo.footer-container-demo > .container > 
.footer-category-demo .footer-link-container-demo .footer-link{
  padding-right: 20px;
  margin: 5px 0;
}

.container .footer-category-demo{
  width: inherit;
}


@media screen and (max-width: 512px) {   

  .footer-container-demo{
      flex-direction: column;
  }
  .footer-container-demo > .logo{
    align-items: center;
    padding: 50px 0 0 0;
  }
  .footer-links-demo > a{
    padding: 10px 0;
    margin: 5px 0;
  }
  .footer-container-demo > .footer-links-demo{
    flex-direction: column;
    padding: 30px 0;
  }
  .container.social-icons {
    justify-content: center!important;
    padding: 0 0 30px;
  }
  .footer-container-demo > .container.social-icons > a{
    margin: 0 30px !important;
  }
  .sub-footer-demo{
    flex-direction: row!important;
    flex-wrap: wrap;
    padding: 10px 20px 30px;
  }
  .sub-footer-demo > .container{
     flex-basis: 50%!important;
    flex-wrap: wrap;
    width: 140px;
    padding: 10px;
  }
  .footer-category-demo{
    text-align: left;
    width: 100%;
  }
}
</style>